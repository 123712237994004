import React from 'react'
import Link from 'gatsby-link'
import './style.css'
// import Timeline from 'react-timeline-semantic-ui';

const LeeInterview = () => (
  <div className='article-page suh-article'>
  <div className="section-content">
  <div className="article-back er-back"><Link to='/research'>&larr;</Link></div>
  <div className="magazine-grid">
  <h1 className="interview-header">Death in the E.R.</h1>
    <div className="interview-head">
    <div className="lead-in">
        Dr. Suh Lee, M.D. is an Emergency Room physician who works at E.R.s in both Charlotte and Durham, NC. She spoke to me about how unprepared most of us are to make life or death decisions for ourselves or our loved ones when facing end of life decisions.
    </div>
    </div>
     <div className="bigQ big-quote-1">"...our society never forces us to confront death or face it in any meaningful way prior to the moment when it’s right there in your face."</div>    
    
    
    <div className="left-column-suh suh-column">
        <div className="question-container i-1">
            <div className="interview-question">
                What is your professional relationship with death?
            </div>
            <div className="interview-answer">
            As an emergency medicine physician, my professional relationship with death ranges from sudden and unexpected deaths from accidents and trauma to people dying from chronic illnesses like cancer. My relationship with the person that is dead/dying is usually a non-verbal one, meaning they are almost always unresponsive or unable to make decisions during my time with them. My conversations regarding death are largely with the “healthcare power of attorney”, generally speaking it is the patient’s spouse, followed by their adult children. I find that the hardest part of my job is delivering news to families that their loved one has passed away. After delivering the bad news, explaining to the best of my abilities what occurred and answering questions they have, I ultimately leave them with a chaplain or a social worker because I have to manage many other patients in the department.
            </div>
        </div>
        <div className="question-container i-2">
            <div className="interview-question">
                How has your relationship with death changed because of your work?
            </div>
            <div className="interview-answer">
                <p>This is a difficult question to answer because I'm not sure I had relationship with death before my work. My work has helped me guide/make recommendations for others actively making decisions around dying. My work has helped me understand what type of code status I would choose for myself if I were faced with a terminal illness.</p>
                <p>Many people despite having the information that something is medically futile still choose to be full code while most doctors and nurses generally choose DNAR (editor’s note: full code is when doctors do many medically invasive procedures to keep you alive, “artificially.” This equates to billions of dollars a year in spending on cases when doctors believe there is little chance of success or that a patient will not be able to return to a meaningful state of life. DNAR means do not attempt to resuscitate. It does not, however, mean do not treat).
                </p>
                <p>The reasons for the disparity between the general public and those in the healthcare field come from distrust of the medical field, feelings of guilt, lack of medical knowledge and the belief that miracles will happen for their situation. When presented with the option of being aggressive or not, many feel a sense of guilt/burden in saying that they held something back or that more could have been done to help a loved one. It’s a really difficult position to be in, especially for someone witho understanding the medical side of it. As physicians, we recognize it’s a very dehumanized and undignified type of death. Additionally, survival does not equate to returning to your life as you knew it. Surviving means leaving the hospital disabled physically and/or mentally. And a lot of doctors I think would rather have a life where they can interact with the world and be who they are instead of returning to a life severely disabled and dependent on others. But beyond that and probably most importantly, I think being a doctor has stressed the importance for me of respecting the wishes of the dying.
                </p>
            </div>
        </div>
        <div className="bigQ big-quote-3">"When presented with the option of being aggressive or not, many feel a sense of guilt/burden in saying that they held something back or that more could have been done to help a loved one. It’s a really difficult position to be in, especially for someone without understanding the medical side of it"</div>

        <div className="question-container i-3">
            <div className="interview-question">What do you think are the most common misconceptions around death in the emergency room?</div>
            <div className="interview-answer">
                <p>Code status is really misunderstood. I find that many people believe you will be treated differently based on what you or your power of attorney choose. The problem with code status is that a lot of times the first time people are ever talking about it is when they are forced to make a decision in a really high-pressure situation with a loved one and they've never talked about it and never thought about it and now they have to make a decision with their own or a loved one's life. And because they've never had that conversation and they're not equipped psychologically or emotionally to make that decision and they have a mental breakdown on the spot and just say, “I don't know. I can't make that decision.” And I don't blame them because our society never forces us to confront death or face it in any meaningful way prior to the moment when it’s right there in your face. This is probably not a condition that is unique to our society but probably a part of what it is to be human. </p>
                <p>
                When doctors come out to the waiting room and say, “your father is dying, have you discussed what his wishes are surrounding his death? Do you want chest compressions, shocks, intubation, etc or do you want DNAR.” It’s difficult to explain what these aggressive/invasive procedures look like/what they are in the short time that I have with them. They don't know that CPR is really violent, breaks ribs, and that you might need a chest tube because a cracked rib punctured a lung. They don't know how painful shocks are if you have any amount of consciousness. They don't understand that being kept alive on a mechanical ventilator means that you're lying in bed unable to move with a tube shoved down your throat. I mean we try our best to sedate them but they're still aware to a certain extent. People don't understand the overall suffering that is created during this process of trying to keep someone alive artificially and what that experience must be like for the patient. And it's not their fault, how could they know? And there wasn’t any formal training for this in my medical school or residency.
                </p>
                <p>
                It’s important as a physician to convey what else can be done for the patient aside from aggressive measures. For example, comfort care means focusing on making the patient pain free, anxiety free, taking away air hunger and  giving someone a chance to say goodbye to their loved ones.
                </p>
            </div>
        </div>
        <div className="bigQ big-quote-2">
        "We need to be better educated about end-of-life medical care. We spend exorbitant amounts of money as a society keeping people alive at the end of their life who will never have, what we as medical professionals believe to be, a meaningful life."
        </div>
        <div className="question-container i-4">
            <div className="interview-question">What can we do to make it better?</div>
            <div className="interview-answer">
                <p>We really fail as a society and as human beings to talk about death and what we want when we are dying. We just avoid it because it’s uncomfortable or awkward. On top of that we often don’t respect the wishes of the dying. We need to be better educated about end-of-life medical care. We spend exorbitant amounts of money as a society keeping people alive at the end of their life who will never have, what we as medical professionals believe to be, a meaningful life.. </p>
                <p>In Europe, it’s up to the physicians to decide who is DNAR and who is full code and in America the onus is on the patient or then the next of kin if the patient doesn't have the capacity to make the decision. But whether a person is appropriately full code or DNAR really depends on the clinical scenario, and whether the reason they are coding is something that can be reversed or not. If it can't be reversed then there is little point of doing the code and traumatizing a dying patient.
                </p>
                <p>Aside from the reversibility of the condition, a large part of the decision making should take into account kind of life you are returning to. Are you able to feed yourself, walk/get around independently, converse with people? I think a lot of doctors would recommend DNAR code status if you cannot return to a functional or meaningful life. But that raises an ethical question, is it for doctors to decide for others what is a meaningful life? </p>
            </div>
        </div>
    
    </div>

    <div className="right-column-suh suh-column">
        <div className="question-container i-5">
            <div className="interview-question">What about about euthanasia?</div>
            <div className="interview-answer">
                <p>Well, it’s illegal so we don’t do it, but it’s interesting. Have you seen The Diving Bell and the Butterfly? It's based on a true story, about the editor of the French Elle magazine, who had a massive brain stem stroke and had locked-in syndrome. Locked-in syndrome is when you're aware of everything, you can see everything and hear everything and understand everything, but you can't move your body at all. The only thing he could do was blink one eye. He learned to communicate with an alphabet board with the aid of a speech therapist. In the film, one of the first things he says with a speech therapist with his communication box is, “let me die,” but ultimately his feelings change and he writes a book called The Diving Bell and the Butterfly.  He feels like he can still thrive and experience beauty by imagining things. The diving bell is his body and the butterfly is his imagination.</p>
                <p>Every case is different, maybe there should be a profession for it. Someone who can explore why you would want to die. Do you want to die because you want to feel a sense of control about your death or can you literally just not handle the pain anymore? You need psychological clearance that this decision is not because you are mentally unsound and want to commit suicide but that you're actually in your right mind and have the capacity to make that decision. It depends on the situation, it's hard to make a formula for every person that wants to die.   </p>
                <p>Psychiatrists, that's their job in the E.R., to determine who needs to be hospitalized for their own safety. Patients that come to the ED with suicidal ideation are held against their will because they're considered to be someone who lacks capacity. It's hard for me because my position as an emergency physician is to clear them from a medical standpoint, and I can say if I agree or not whether someone should be discharged to self or be committed to the hospital, but generally that judgment I defer to a psychiatrist.</p>
            </div>
        </div>
        <div className="question-container i-6">
            <div className="interview-question">
            Have your opinions around change about death?
            </div>
            <div className="interview-answer">
                <p>I have less fear about dying then I did before. but also I see more how it's a problem in our society because of this unusual coupling of deciding our type of death with limited understanding/information (as opposed to Europe where physicians decide) along with human nature to avoid the topic of death and dying.
                </p>
                <p></p>
                <p></p>
            </div>
        </div>
        <div className="question-container i-7">
            <div className="interview-question">Do you see cases where it’s different, where people do face it?</div>
            <div className="interview-answer">
                <p>Rarely, but yes in the context of chronic illness with a poor projected clinical course. Funny thing is that there are a lot of people like that that still haven’t talked about death even though they know they are actively dying from terminal cancer or something with a similar projected limited time course.</p>
                <p></p>
                <p></p>
            </div>
        </div>
        <div className="bigQ">
        "In the comfort care scenario, people get to die on their own terms and they have a chance to say goodbye, versus the aggressive option where they go to the operating room and they die violently on the table. And getting a chance to say goodbye on your own terms seems like it can be a powerful part of the grieving process for the family and the loved ones."
        </div>
        <div className="question-container i-8">
            <div className="interview-question">How is confronting death beneficial? Are we making progress?</div>
            <div className="interview-answer">
                <p>When you are at this crossroads between pursuing aggressive care with a high mortality rate vs comfort care there is a powerful part and value of comfort care that people don’t get to see. In the comfort care scenario, people get to die on their own terms and they have a chance to say goodbye, versus the aggressive option where they go to the operating room and they die violently on the table. And getting a chance to say goodbye on your own terms seems like it can be a powerful part of the grieving process for the family and the loved ones.</p>
                <p>There have been studies showing that if you allow family members to witness the CPR, shocks, etc and all the things that are done when trying to resuscitate someone, there is evidence saying that it helps people understand the reality of the death and results in less PTSD. This is interesting because it seems that witnessing/confronting death in this scenario provides some type of psychological/emotional benefit. In the same way that witnessing dying is a type of confrontation with death, I suspect there would be a similar benefit with confronting death/dying in our society via frank discussions prior to the actual occurrence of someone’s death. </p>
            </div>
        </div>
        <div className="question-container i-9">
            <div className="interview-question">Have you thought about the moment people die?</div>
            <div className="interview-answer">
                <p>Yeah, it can be surprisingly peaceful, though it isn’t always. For example, if you are bleeding to death, your brain stops being perfused with blood & you basically fall asleep. </p>
            </div>
        </div>
    </div>

   


    </div>
    </div>
  </div>
)

export default LeeInterview
